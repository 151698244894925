import { template as template_1b6a460260304a8aa942ecc54d7d6052 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1b6a460260304a8aa942ecc54d7d6052(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
