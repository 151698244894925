import { template as template_5bb8f8f3668943d0a5f2da830169de9c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5bb8f8f3668943d0a5f2da830169de9c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
