import { template as template_f645591d39fb4da182904b0505994a1d } from "@ember/template-compiler";
const WelcomeHeader = template_f645591d39fb4da182904b0505994a1d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
