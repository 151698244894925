import { template as template_d38fe9343759495eba10e0507f98cf19 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d38fe9343759495eba10e0507f98cf19(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
