import { template as template_aebfb383b9c444959b70252d9f86015a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_aebfb383b9c444959b70252d9f86015a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
